import ChecksReport from '../components/ChecksReport.vue'
import api from './api'

export default {
    getuser(){
    return api.execute(`get`,`/manager/getusers`)
    },
    changestatus(data){
      return api.execute(`post`,`/manager/changestatus`,data)
    },
    changeiwitness(data){
        return api.execute(`post`,`/manager/iwitness`,data)
    },
    managerdash(data){
      if(window.localStorage.getItem("user") == 'moderator'){
        return api.execute(`post`,`/moderator/managerdash`,data)
      } else {
        return api.execute(`post`,`/manager/managerdash`,data)
      }
    },
    getdim(data){
      if(window.localStorage.getItem("user") == 'moderator'){
        return api.execute(`post`,`/moderator/getdim`,data)
      } else {
        return api.execute(`post`,`/manager/getdim`,data)
      }
    },
    editUser(data){
      return api.execute(`post`,`/manager/edituser`,data)
    },
    camplist(){
      if(window.localStorage.getItem("user") == 'moderator'){
        return api.execute(`post`,`/moderator/camplist`)
      } else {
        return api.execute(`post`,`/manager/camplist`)
      }
    },
    createplan(data){
      return api.execute(`post`,`/manager/createplan`,data)
    },
    editplan(data){
      return api.execute(`PUT`,`/manager/plan`,data)
    },
    deleteplan(data){
      return api.execute(`DELETE`,`/manager/plan`,data)
    },
    getplan(){
      return api.execute(`get`,`/manager/plans`)
    },
    getAllocatePlans(){
      return api.execute(`get`,`/manager/allocateplan`)
    },
    AllocatePlans(data){
      return api.execute(`post`,`/manager/allocateplan`,data)
    },
    EditAllocatePlans(data){
      return api.execute(`put`,`/manager/allocateplan`,data)
    },
    ErrorLogs(data){
      return api.execute(`post`,`/manager/error`,data)
    },
    getwpnsearch(){
      return api.execute(`post`,`/manager/wnpsdata`)
    },
    deletewpnsearch(data){
      return api.execute(`delete`,`/manager/wnpsdata`,data)
    },
    getoptions(){
      if(window.localStorage.getItem("user") == 'moderator'){
        return api.execute(`get`,`/moderator/options`)
      } else {
        return api.execute(`get`,`/manager/options`)
      }
    },
    wildlogin(data){
      return api.execute(`post`,`/manager/wildlogin`, data)
    },
    genDefaultCampign(data) {
      return api.execute(`post`,`/manager/gendef`, data)
    },
    getProduct(){
      return api.execute(`get`,`/manager/products`)
    },
    changeUserRole(data){
      return api.execute(`post`,`/manager/change/role`, data)
    },
    getRoles(){
      return api.execute(`get`,`/manager/roles`)
    },
    ChecksReport(data){
      return api.execute(`post`,`/manager/checks/report`,data)
    },
    getUserEvents(){
      if(window.localStorage.getItem("user") == 'moderator'){
        return api.execute(`get`,`/moderator/user/events`)
      }
      return api.execute(`get`,`/manager/user/events`)
    },
    updateUserEvents(data){
      if(window.localStorage.getItem("user") == 'moderator'){
        return api.execute(`put`,`/moderator/user/events`,data)
      }
      return api.execute(`put`,`/manager/user/events`,data)
    }
}
